//
// Main
//

// Body
body {
  background-color: var(--#{$prefix}page-bg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% get($page-bg-image-height, desktop);

  &.page-bg-image-lg {
    background-size: 100% get($page-bg-image-height-lg, desktop);
  }
}

//@include color-mode(light) {
//  body:not(.app-blank) {
//    background-image: url('../media/patterns/header-bg.jpg');
//  }
//}
//
//@include color-mode(dark) {
//  body:not(.app-blank) {
//    background-image: url('../media/patterns/header-bg-dark.png');
//  }
//}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

.page-bg {
  background-color: var(--#{$prefix}page-bg) !important;
}

.app-blank {
  background-color: var(--#{$prefix}app-blank-bg) !important;
}

// Nav custom
.nav-custom {
  .nav-link {
    color: $white !important;
    opacity: 0.7;

    &.active {
      opacity: 1;
      border-bottom: 2px solid $white !important;
    }

    &:hover {
      opacity: 1;
      border-bottom-color: transparent !important;
    }
  }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  // Wrapper
  .wrapper {
    transition: padding-left get($aside-config, transition-speed) ease,
      margin-right get($aside-config, transition-speed) ease;

    // Fixed header mode
    .header-fixed[data-kt-sticky-header='on'] & {
      padding-top: get($header-config, desktop, default, height);
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Body
  body {
    background-size: 100% get($page-bg-image-height, tablet-and-mobile);

    &.page-bg-image-lg {
      background-size: 100% get($page-bg-image-height-lg, tablet-and-mobile);
    }
  }

  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed[data-kt-sticky-header='on'] & {
      padding-top: get($header-config, tablet-and-mobile, default, height);
    }
  }
}

.bg-dark {
  background: var(--#{$prefix}card-cap-bg);
}

.menu-sub-lg-down-accordion {
  top: 15px !important;
}

.heart-icon {
  &:hover {
    color: #009ef7 !important;
  }
}
.cart-icon {
  &:hover {
    color: #009ef7 !important;
  }
}

.bg-text-muted {
  background: #181c32 !important;
  .active {
    background-color: #a1a5b7 !important;
  }
}

.ki-night-day {
  color: #404148 !important;
  &:hover {
    color: #009ef7 !important;
  }
}

.react-multi-carousel-list {
  // height: 350px;
  margin-top: 0 !important;
}

.menu-link:hover {
  background: #0e0e15 !important;
}

.btn.btn-active-light-primary:hover:not(.btn-active) {
  background-color: transparent !important;
}

.global-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.relation-dd {
  button {
    width: 100%;
    text-align: start;
    &:after {
      float: right;
      margin-top: 10px;
    }
  }
}