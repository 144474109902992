.custom-switch .form-check-input {
    background-color: #e56471;
    border-color: #dc3545;
  }
  
  .custom-switch .form-check-input:checked {
    background-color: #28a745; 
    border-color: #28a745;
  }
  
  .custom-switch .form-check-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .custom-switch .form-check-input:focus:not(:checked) {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }

  .table-center th, .table-center td {
    text-align: center;
    vertical-align: middle;
  }

  .table-center th {
    font-weight: 800 !important;
  }

  .image-uploader {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .upload-button {
    padding: 10px 20px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  .checkbox-status input {
    width: 80px !important;
    height: 30px;
  }