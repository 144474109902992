.abs-banner {
    top: 45%;
    bottom: 0;
    left: 0;
    right: 0;
    h5 {
        color: #fff;
        letter-spacing: 3px;
        font-size: 20px;
        cursor: pointer;
    }
}

.react-multi-carousel-item {
    width: 407px;
}
/* Play button container */
.center-play-button {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 15px;
    color: white;
    z-index: 1;
    transition: transform 0.3s ease;
    &.top-40 {
      top: 40%;
    }
  }
  
  .center-play-button:hover {
    transform: translate(-50%, -50%) scale(1.2);
  }
  
  /* Font Awesome play icon */
  .center-play-button i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: calc(1.5em + 1.55rem + 2px);
    width: calc(1.5em + 1.55rem + 2px);
  }
  
  /* Circle animation (pseudo-elements) */
  /* Start with no animation and no opacity */
  .center-play-button::before,
  .center-play-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  /* Circle grow and animate on hover */
  .center-play-button:hover::before,
  .center-play-button:hover::after {
    opacity: 1;
    animation: wave 1.5s infinite;
  }
  
  .center-play-button:hover::after {
    animation-delay: 0.3s; /* Delay for the second circle */
  }
  
  /* Wave animation */
  @keyframes wave {
    0% {
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }
  
  .pink-card {
    background: linear-gradient(90deg, #ffbf96, #fe7096);
  }

  .blue-card {
    background: linear-gradient(90deg, #90caf9, #047edf 99%);
  }

  .green-card {
    background: linear-gradient(90deg, #84d9d2, #07cdae);
  }